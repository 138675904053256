@import '../../style.scss';
.equipe{
    background: #f2f2f2;
    width: 100%;
    padding: 50px 0;
    .equipe-wrapper{
        max-width: 1025px;
        margin: 0 auto;
        @include laptop{
            width: 900px;
        }
        @include tablette{
            width: 850px;
        }
        @include mobileXR{
            width: 370px;
            position: relative;
            overflow: hidden;
        }
        @include mobileSE{
            width: 340px;
        }
        .client-cont-title{
            display: flex;
            flex-direction: column;
            gap: 10px;
            text-align: center;
            .client-sous-title{
                width: 60%;
                margin: 0 auto;
                font-size: 2rem;
                font-family: 'Anton', sans-serif;
                color: rgb(21, 21, 65);
                @include mobile{
                    width: 100%;
                 }
            }
            .apropos-barre{
                display: flex;
                align-items: center;
                margin: 0 auto;
                height: 5px;
                width: 15%;
                background: rgb(21, 21, 65);
                overflow: hidden;
                .apropos-moov{
                    background: #fff;
                    height: 100%;
                    width: 7px;
                    animation: barAnim 10s infinite;

                    @keyframes barAnim {
                        0%{
                            transform: translateX(0px);
                        }
                        50%{
                            transform: translateX(142px);
                        }
                        100%{
                            transform: translateX(0px);
                        }
                    }
                }
            }
        }

        .equipe-container{
            position: relative;
            width: 100%;
            margin-top: 100px;
            .equipe-rows{
                width: 100%;
                display: grid;
                grid-template-columns: repeat(3,1fr);
                align-items: center;
                gap: 40px;
                @include mobile{
                    display: flex;
                    flex-direction: column;
                }
                .equipe-row{
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    .equipe-top{
                        width: 100%;
                        .equipe-img{
                            width: 90%;
                            object-fit: cover;
                            margin-bottom: 50px;
                            @include mobile{
                                width: 100%;
                            }
                        }
                    }
                    .equipe-bottom{
                        width: 90%;
                        position: absolute;
                        bottom: 25px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include mobile{
                            width: 100%;
                        }
                        .equipe-bottom-rows{
                            box-shadow: 0px 4px 14px hsl(355deg 25% 15% / 10%);
                            background: #fff;
                            border-radius: 10px 10px 0 0;
                            width: 80%;
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            text-align: center;
                            padding: 10px;
                            .equipe-name{
                                font-family: 'Anton', sans-serif;
                                font-size: 1.1rem;
                                font-weight: 700;
                                letter-spacing: 1.5px;
                                color: rgb(49, 48, 48);
                                @include mobile{
                                    font-size: .9rem;
                                }
                            }
                            .equipe-h3{
                                font-family: 'Anton', sans-serif;
                                letter-spacing: 1px;
                                font-weight: 200;
                                font-size: .8rem;
                                color: gray;
                                @include mobile{
                                    font-size: .7rem;
                                }
                            }
                            .equipe-rx{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 10px;
                                .equipe-rows-rx{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    height: 35px;
                                    width: 35px;
                                    border-radius: 50%;
                                    border: 1px solid gray;
                                    @include mobile{
                                        height: 25px;
                                        width: 25px;
                                    }
                                    cursor: pointer;
                                    &:hover{
                                        transform: scale(1.1);
                                    }
                                    .equipe-icon{
                                        color: rgb(49, 48, 48);
                                        font-size: 1.4rem;
                                        @include mobile{
                                            font-size: 1rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}