/* @import url('https://fonts.googleapis.com/css2?family=Anton&family=Roboto:wght@100;300;400;500;700;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Nunito:wght@200;300;400;500;600;700;800&display=swap');

*{
  padding:0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
}

a{text-decoration: none;}
li{list-style: none;}