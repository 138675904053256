@import '../../style.scss';
.formation{
    width: 100%;
    padding: 100px 0;
    .formation-wrapper{
        max-width: 1025px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap-reverse;
        gap: 30px;
        @include laptop{
            width: 900px;
        }
        @include tablette{
            width: 850px;
        }
        @include mobile{
            width: 400px;
            flex-direction: column;
        }
        @include mobileXR{
            width: 370px;
            position: relative;
            overflow: hidden;
        }
        @include mobile12pro{
            width: 350px;
        }
        @include mobileSE{
            width: 340px;
        }
        .formation-left{
            flex: 1;
            width: 100%;
            .client-cont-title{
                display: flex;
                flex-direction: column;
                gap: 10px;
                position: relative;

                .client-title{
                    font-size: 1.3rem;
                    color: rgb(21, 21, 65);
                    @include mobile{
                        font-size: 1rem;
                    }
                }
                .client-sous-title{
                    font-size: 2rem;
                    font-family: 'Anton', sans-serif;
                    color: rgb(21, 21, 65); 
                }
                .apropos-barre{
                    position: relative;
                    display: flex;
                    align-items: center;
                    height: 5px;
                    width: 25%;
                    background: rgb(21, 21, 65);

                    .apropos-moov{
                        background: #fff;
                        height: 100%;
                        width: 7px;
                        animation: barAnim 10s infinite;

                        @keyframes barAnim {
                            0%{
                                transform: translateX(0px);
                            }
                            50%{
                                transform: translateX(142px);
                            }
                            100%{
                                transform: translateX(0px);
                            }
                        }
                    }
                }
            }
            .formation-text{
                padding: 40px 0;
                .formation-desc{
                    color: grey;
                    line-height: 1.9;
                    text-align: justify;
                    font-weight: 300;
                }
            }
        }
        .formation-right{
            flex: 1;
            .formation-img{
                width: 100%;
            }
        }
        
    }
}