@import '../../style.scss';
.faq{
    width: 100%;
    padding: 50px 0;
    background: linear-gradient(
        rgba(209, 207, 207, 0.329),rgba(189, 184, 184, 0.671)), url('../../img/hero-bg.png') no-repeat;
    background-position: center;
    background-size: cover;

    .faq-wrapper{
        max-width: 1025px;
        margin: 0 auto;
        @include tablette{
            width: 850px;
        }
        @include mobileXR{
            width: 370px;
            position: relative;
            overflow: hidden;
        }
        @include mobile12pro{
            width: 350px;
        }
        @include mobileSE{
            width: 345px;
        }
        .client-cont-title{
            display: flex;
            flex-direction: column;
            gap: 10px;
            text-align: center;
            .client-title{
                font-size: 1.2rem;
                color: rgb(21, 21, 65);
            }
            .client-sous-title{
                width: 60%;
                margin: 0 auto;
                font-size: 2rem;
                font-family: 'Anton', sans-serif;
                color: rgb(21, 21, 65);
                @include mobile{
                    width: 100%;
                 }
            }
            .apropos-barre{
                display: flex;
                align-items: center;
                margin: 0 auto;
                height: 5px;
                width: 15%;
                background: rgb(21, 21, 65);
                overflow: hidden;
                .apropos-moov{
                    background: #fff;
                    height: 100%;
                    width: 7px;
                    animation: barAnim 10s infinite;

                    @keyframes barAnim {
                        0%{
                            transform: translateX(0px);
                        }
                        50%{
                            transform: translateX(142px);
                        }
                        100%{
                            transform: translateX(0px);
                        }
                    }
                }
            }
        }
        .faq-container{
            width: 100%;
            .faq-rows{
                width: 100%;
                margin-top: 100px;
                display: grid;
                grid-template-columns: repeat(2,1fr);
                gap: 20px;
                @include mobile{
                    display: flex;
                    flex-direction: column;
                }
                .question-row{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    .visible-pannel{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 20px;
                        background: linear-gradient(rgb(21, 21, 65),rgba(21, 21, 65, 0.836));
                        border-radius: 10px;
                        box-shadow: 0px 0px 5px rgba(0,0,0,0.100);
                        border: 1px solid rgba(158, 154, 154, 0.548);
                        z-index: 10;
                        cursor: pointer;
                        &:hover{
                            background: rgb(21, 21, 65);
                        }
                        .question-h2{
                            font-family: 'Anton', sans-serif;
                            font-size: .9rem;
                            color: #fff;
                            font-weight: 400;
                        }
                        .icons-plus{
                            font-size: 1.2rem;
                            color:#fff!important;
                        }
                    }
                    .toggle-pannel{
                        padding: 0 20px;
                        max-height: 0;
                        overflow: hidden;
                        background: #f7f4f4;
                        border-radius: 10px;
                        box-shadow: 0px 0px 5px rgba(0,0,0,0.100);
                        .question-desc{
                            line-height: 1.5;
                            color: rgb(8, 7, 7);
                            letter-spacing: .5;
                            padding: 10px 0;
                        }
                    }
                    .active{
                        max-height: 100%;
                    }
                }
            }
        }
    }
}