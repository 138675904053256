@import '../../style.scss';
.client{ 
    width: 100%;
    padding: 50px 0;
    background: #f2f2f2;
    .client-wrapper{
        max-width: 1025px;
        margin: 0 auto;
        @include laptop{
            width: 900px;
        }
        @include tablette{
            width: 850px;
        }
        @include mobile{
            width: 400px;
        }
        @include mobileXR{
            width: 370px;
        }
        @include mobile12pro{
            width: 350px;
        }
        @include mobileSE{
            width: 340px;
        }
        .client-cont-title{
            display: flex;
            flex-direction: column;
            gap: 10px;
            text-align: center;
            .client-title{
                font-size: 1.2rem;
                color: rgb(21, 21, 65);
            }
            .client-sous-title{
                width: 60%;
                margin: 0 auto;
                font-size: 2rem;
                font-family: 'Anton', sans-serif;
                color: rgb(21, 21, 65);
                @include mobile{
                    width: 100%;
                 }
            }
            .apropos-barre{
                display: flex;
                align-items: center;
                margin: 0 auto;
                height: 5px;
                width: 15%;
                background: rgb(21, 21, 65);
                overflow: hidden;
                .apropos-moov{
                    background: #fff;
                    height: 100%;
                    width: 7px;
                    animation: barAnim 10s infinite;

                    @keyframes barAnim {
                        0%{
                            transform: translateX(0px);
                        }
                        50%{
                            transform: translateX(142px);
                        }
                        100%{
                            transform: translateX(0px);
                        }
                    }
                }
            }
        }
        .client-container{
            display: grid;
            grid-template-columns: repeat(3,1fr);
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin: 0 auto;
            gap: 20px;
            margin-top: 100px;
            @include mobile{
                display: flex;
                flex-direction: column;
            }
            .client-row{
                width: 100%;
                box-shadow: -1px 15px 17px 0px rgba(0, 0, 0, 0.082);
                border : 2px solid rgba(180, 180, 180, 0.685);
                display: flex;
                align-items: center;
                justify-content: center;
                height: 200px;
                padding: 20px;
                background: #fff;

                .client-img{
                    width: 70%;
                    height: 100%;
                    object-fit: cover;
                    opacity: 1;
                    cursor: pointer;
                    &:hover{
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
}