.slider-row1{
  background: radial-gradient(rgba(0, 0, 0, 0.699),rgba(0, 0, 0, 0.685)), url('../../../img/970\ X\ 250\ bannière\ 1.png') no-repeat;
  height: calc(100vh - 50px);
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.slider-row2{
  background: radial-gradient(rgba(0, 0, 0, 0.699),rgba(0, 0, 0, 0.637)), url('../../../img/970\ X\ 250\ bannière\ 2.png') no-repeat;
  height: calc(100vh - 50px);
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.slider-row3{
  background: radial-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9)), url('../../../img/970\ X\ 250\ bannière\ 3.png') no-repeat;
  height: calc(100vh - 60px);
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.slider-row4 {
  background: radial-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9)), url('../../../img/970%20X%20250%20banni%C3%A8re%204.png') no-repeat;
  height: calc(100vh - 50px);
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.slider-row1,
.slider-row2,
.slider-row3,
.slider-row4 {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

@keyframes zoom-in {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.custom-swiper .swiper-pagination-bullet {
  display: none;
}

.slider-row1:hover,
.slider-row2:hover,
.slider-row3:hover,
.slider-row4:hover {
  animation: zoom-in 5s infinite; /* Applique l'animation de zoom pendant 5 secondes en boucle */
}

.slider-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 10px;
  width: 70%;
  padding: 10px;
}
.sliders-h1{
  font-family: 'Anton', sans-serif;
  color: rgb(21, 21, 65);
  font-size: 3.3rem;
  letter-spacing: 8px;
  font-weight: bolder;
  color: transparent;
      -webkit-text-stroke-width: 3px;
      -webkit-text-stroke-color: #fff;
}

.slider-desc{
  font-size: 1rem;
  letter-spacing: .9px;
  font-weight: 300;
  color:#fff;
  line-height: 1.5;
  padding: 30px 0;
  text-align: center;
}
.sliders-row{
  display: flex;
  align-items: center;
}

.slider-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 10px;
  border-radius: 20px;
  border: 1px solid #fff;
  color: #fff;
  font-size: .9rem;
  width: 150px;
  margin-right: 20px;
  overflow: hidden; /* Empêche le contenu de déborder pendant l'animation */
  position: relative;
}

.icons-btn {
  font-size: 1.5rem;
  padding-left: 5px;
}

.slider-btn:hover {
  color: black;
  transition: color 0.3s ease;
}

.slider-btn:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #fff, transparent);
  animation: water 1s linear infinite;
}

@keyframes water {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.icon-white{
  background: #fff;
  color: black;
}

@media screen and (max-width:992px){
  .swiper-container{
    width: 100%;
    overflow: hidden;
  }
  .slider-row1, .slider-row2, .slider-row3, .slider-row4{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
  }
  .slider-wrapper{
    width: 90%;
  }
  .sliders-h1{
    font-size: 2.5rem;
  }
  .slider-desc{
    font-size: .9rem;
  }
  .slider-btn{
    padding: 7px 15px;
    font-size: .9rem;
  }
  .icons-btn{
    font-size: 1.2rem;
  }

}
@media screen and (max-width: 768px){
  .swiper-container{
    width: 100%;
    overflow: hidden;
  }
}
@media screen and (max-width:568px){
  .slider-wrapper{
    width: 95%;
  }
  .sliders-h1{
    text-align: center;
    font-size: 2.8rem;
    letter-spacing: 6px;
  }
  .slider-desc{
    font-size: .8rem;
  }
  .slider-btn{
    font-size: .8rem;
    padding: 5px 15px;
  }

}
@media screen and (max-width:414px){
  .swiper-container{
    width: 100%;
    overflow: hidden;
  }
  .slider-wrapper{
    width: 95%;
  }
  .slider-row1,.slider-row2,.slider-row3,.slider-row4{
    height: calc(40vh - 50px);
  }
  .sliders-h1{
    font-size: 2.5rem;
    font-weight: bolder;
  }
  .slider-desc{
    display: none;
  }
  .sliders-row{
    margin-top: 20px;
  }
  .slider-btn{
    font-size: .8rem;
    padding: 4px 7px;
    width: 140px;
  }

}
@media screen and (max-width:390px){
  .slider-wrapper{
    width: 95%;
  }
  .slider-row1,.slider-row2,.slider-row3,.slider-row4{
    height: calc(40vh - 50px);
  }
  .slider-btn {
    padding: 4px 7px;
    width: 130px;
  }
}

@media screen and (max-width:375px){
  .slider-wrapper{
    width: 95%;
  }
  .slider-row1,.slider-row2,.slider-row3,.slider-row4{
    height: calc(50vh - 50px);
  }
    .sliders-h1{
    font-size: 2.3rem;
    font-weight: bolder;
  }
}