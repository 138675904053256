@import '../../style.scss';
.villes{
    background: #fff;
    width: 100%;
    padding: 50px 0;
    .villes-wrapper{
        max-width: 1025px;
        margin: 0 auto;
        @include laptop{
            width: 900px;
        }
        @include tablette{
            width: 850px;
        }
        @include mobile{
            width: 400px;
        }
        @include mobileXR{
            width: 370px;
            position: relative;
            overflow: hidden;
        }
        @include mobile12pro{
            width: 350px;
        }
        @include mobileSE{
            width: 340px;
        }
        .client-cont-title{
            display: flex;
            flex-direction: column;
            gap: 10px;
            text-align: center;
            .client-sous-title{
                width: 60%;
                margin: 0 auto;
                font-size: 2rem;
                font-family: 'Anton', sans-serif;
                color: rgb(21, 21, 65);
                @include mobile{
                    width: 100%;
                 }
            }
            .apropos-barre{
                display: flex;
                align-items: center;
                margin: 0 auto;
                height: 5px;
                width: 15%;
                background: rgb(21, 21, 65);
                overflow: hidden;
                .apropos-moov{
                    background: #fff;
                    height: 100%;
                    width: 7px;
                    animation: barAnim 10s infinite;

                    @keyframes barAnim {
                        0%{
                            transform: translateX(0px);
                        }
                        50%{
                            transform: translateX(142px);
                        }
                        100%{
                            transform: translateX(0px);
                        }
                    }
                }
            }
        }

        .villes-container{
            width: 100%;
            padding-top: 100px;
            .villes-rows{
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                @include mobile{
                    display: flex;
                    flex-direction: column;
                }
                .villes-row{
                    padding: 20px;
                    background: #fff;
                    border: 1px solid #f2f2f2;
                    transition: 0.5s;
                    cursor: pointer;
                    box-shadow: 0px 4px 14px hsl(355deg 25% 15% / 10%);
                    &:hover{
                        transform: scale(1.1);
                    }
                    .villes-image{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .villes-img{
                            width: 40px;
                            height: 40px;
                            background: rgba(194, 4, 4, 0.219);
                            padding: 5px;
                            border-radius: 50px;
                        }
                    }
                    h2{
                        font-size: 1rem;
                        color: rgb(68, 66, 66);
                        text-align: center;
                        padding-top: 20px;
                        font-weight: 300;
                    }
                }
            }
        }
    }
}