@import '../../style.scss';
.slider{
    position: relative;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 50px);
    background: linear-gradient(rgba(104, 104, 158, 0.055),rgba(81, 81, 180, 0.021)),url('./../../img/hero-bg.png') no-repeat;
    background-position: center;
    @include laptop{
        height: calc(100vh - 50px);
    }
    @include mobileXR{
        background: linear-gradient(rgba(104, 104, 158, 0.521),rgba(81, 81, 180, 0.247)),url('./../../img/apropos4.jpg') no-repeat;
        height: calc(43vh - 50px);
    }
    @include mobileSE{
        height: calc(55vh - 50px);
        width: 100%;
        position: relative;
        overflow: hidden;
    }
    .slider-container{
        width: 1025px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 60px;
        gap: 30px;
        @include laptop{
            width: 900px;
        }
        @include tablette{
            width: 850px;
        }
        @include mobile{
            width: 400px;
            display: flex;
            flex-direction: column;
        }
        @include mobileXR{
            width: 350px;
            position: relative;
            overflow: hidden;
        }
        @include mobileSE{
            width: 340px;
        }
        .slider-left{
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            @include mobileXR{
                align-items: center;
            }
            .slider-h1{
                font-family: 'Anton', sans-serif;
                color: rgb(21, 21, 65);
                font-size: 3.8rem;
                letter-spacing: 5px;
                font-weight: bolder;
                color: transparent;
                    -webkit-text-stroke-width: 3px;
                    -webkit-text-stroke-color: rgb(21, 21, 65);
                @include mobileXR{
                    font-size: 2.8rem;
                    text-align: center;
                    color: transparent;
                    -webkit-text-stroke-width: 1px;
                    -webkit-text-stroke-color: #fff;
                    letter-spacing: 15;
                }
                @include mobileSE{
                    color: transparent;
                    -webkit-text-stroke-width: 1px;
                    -webkit-text-stroke-color: #fff;
                    font-size: 2.5rem;
                    letter-spacing: 15;
                }
            }

            .slider-desc{
                font-size: 1rem;
                letter-spacing: .9px;
                font-weight: 300;
                color: rgb(73, 72, 72);
                line-height: 1.5;
                padding: 30px 0;
                text-align: justify;
                strong{
                    color: rgb(21, 21, 65);
                    font-weight: 800;
                }
                @include mobileXR{
                    display: none;
                    font-size: .9rem;
                }
            }
            .slider-rows-btn{
                display: flex;
                align-items: center;
                gap: 20px;
                .slider-btn{
                    padding: 8px 12px;
                    border : 2px solid rgb(21, 21, 65);
                    color: rgb(21, 21, 65);
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    font-size: .9rem;
                    transition: .5s;
                    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.151);
                    @include mobileXR{
                        border : 2px solid #fff;
                        color: #fff;
                        margin-top: 50px;
                        padding: 5px 10px;
                        font-size: .8rem;
                        border-radius: 0;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 40%;
                    }
                    &:hover{
                        background: #fff;
                        color: rgb(21, 21, 65);
                    }
                    .icons-btn{
                        width: 20px;
                        margin-left: 5px;
                    }
                }
                .slider-diff{
                    transition: .5s;
                    background: rgba(15, 15, 54, 0.952);
                    color: #fff;
                    @include mobileXR{
                        border : 2px solid #fff;
                        color: #fff;
                        margin-top: 50px;
                        padding: 5px 10px;
                        font-size: .8rem;
                        border-radius: 0;
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 40%;
                    }
                    &:hover{
                        border : 2px solid rgb(21, 21, 65);
                        color: rgb(21, 21, 65);
                        background: #fff;
                    }
                }
            }
        }
        .slider-right{
            flex: 1;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            position: relative;
            overflow: hidden;
            @include mobile{
                display: none;
            }
            .slider-img{
                width: 100%;
                height: 80%;
                object-fit: cover;
            }
        }
    }
}