@import '../../style.scss';

.navbar{
    width: 100%;
    position: sticky;
    top: 0;
    padding: 10px 0;
    z-index: 111;
    background: #fff;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.151);
    transition: .5s;
    
    @include tablette{
        width: 100%;
    } 

    @include mobile{
        width: 100%;
        position: relative;
        overflow: hidden;
    } 
    .navbar-wrapper{
        width: 1025px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include laptop{
            width: 900px;
         }
        @include tablette{
            width: 860px;
        }
        @include mobileXR{
            width: 365px;
            position: relative;
            overflow: hidden;

        } 
        @include mobileSE{
            width: 340px;
        } 

        .navbar-logo{
            height: 50px;
            border-radius: 50px;
        }
        .navbar-ul{
            position: relative;
            display: flex;
            align-items: center;
            gap: 30px;
            width: 100%;
            overflow: hidden;
            @include tablette{
                position: fixed;
                top: 68px;
                bottom:0;
                padding-top: 40px;
                height: 100vh;
                width: 50%;
                right: -500px;
                background: linear-gradient(rgb(21, 21, 65),rgba(13, 13, 36, 0.911));
                flex-direction: column;
                transition: 0.7s;
                z-index: 11;
            }
            @include mobile{
                
            }
            .navbar-link{
                display: flex;
                align-items: center;
                color: rgb(21, 21, 65);
                font-size: .9rem;
                transition: .5s;  
                padding: 10px 0;
                letter-spacing: 1px;
                transition: .5s;
                font-weight: 300;
                &:hover{
                    border-bottom: 2px solid rgb(21, 21, 65);
                }
                @include tablette{
                    color: #fff;
                } 
            }
            .navbar-rsx{
                border: 1px solid rgba(21, 21, 65, 0.603);
                box-shadow: 0px 4px 14px hsl(355deg 25% 15% / 10%);
                padding: 5px 15px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                gap: 10px;
                .nav-bar-login{
                    a{
                        color: rgb(21, 21, 65);
                        font-size: .9rem;
                        transition: .5s; 
                        font-weight: 300;
                        display: flex;
                        align-items: center;
                        &:hover{
                            transform: scale(1);
                        }
                        .login-icon{
                            color: rgb(21, 21, 65);;
                            font-size: .9rem;
                        }
                    }
                }


            }
            .navbar-rsx-blue{
                border: 1px solid #fff;
                box-shadow: 0px 4px 14px hsl(355deg 25% 15% / 10%);
                padding: 5px 15px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                gap: 10px;

                .nav-bar-login{
                    a{
                        color:#fff;
                        &:hover{
                            transform: scale(1);
                        }
                        .login-icon{
                            color: #fff;
                            font-size: .9rem;
                        }
                    }
                }

/*                 .navbar-icon{
                    color: #fff;
                    font-size: 1.2rem;
                    cursor: pointer;
                    &:hover{
                        transform: scale(1.1);
                    }
                } */
            }
        }.navbarOpen{
            right: 0;
        }
        .navbar-bar{
            padding: 4px;
            border: 2px solid rgb(21, 21, 65);
            font-size: 2.3rem;
            display: none;
            border-radius: 5px;
            cursor: pointer;
            @include tablette{
                display: block;
                color: rgb(7, 7, 71);
            } 
        }
        .active-red{
            color: red;
        }
    }
}
.navbar.bleu{
    position: fixed;
    background: rgb(21, 21, 65);
    z-index: 222;
    transition: .5s;
    @include tablette{
       background: #fff;
    } 
}
.bleu-link{
    color: #fff;
    display: flex;
    align-items: center;
    font-size: .9rem;
    transition: .5s;
    font-weight: 300;
    padding: 10px 0;
    letter-spacing: 1px;
    @include tablette{
        color: #fff;
     }
    &:hover{
        border-bottom: 1px solid #f2f2f2;
    }
}


