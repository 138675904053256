@import '../../style.scss';

.apropos{
    width: 100%;
    margin: 100px 0;
    @include tablette{
        display: flex;
        flex-direction: column;
        padding: 0 50px;
    }
    @include mobile{
        padding: 20px;
        margin: 50px 0;
    }
   
    .apropos-wrapper{
        position: relative;
        max-width: 1025px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        overflow: hidden;
        @include laptop{
           width: 900px;
           margin: 0 auto;
        }
        @include tablette{
            display: flex;
            flex-direction: column;
            width: 850px;
        }
        @include miniTablette{
            width: 500px;
            position: relative;
            overflow: hidden;
        }
        @include mobileXR{
            width: 355px;
            position: relative;
            overflow: hidden;
        }
        @include mobile12pro{
            width: 340px;
        }
        @include mobileSE{
            width: 330px;
        }
        .apropos-left{
            flex: 1;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .apropos-cont-title{
                display: flex;
                flex-direction: column;
                gap: 10px;
                position: relative;
                @include mobile{
                    text-align: center;
                    align-items: center;
                }
                .apropos-h1{
                    font-size: 1.2rem;
                    color: rgb(21, 21, 65);
                    @include mobile{
                        font-size: 1rem;
                    }
                }
                .apropos-sous-title{
                    font-size: 2rem;
                    font-family: 'Anton', sans-serif;
                    color: rgb(21, 21, 65); 
                    @include mobile{
                        width: 100%;
                    }
                }
                .apropos-barre{
                    position: relative;
                    display: flex;
                    align-items: center;
                    height: 5px;
                    width: 25%;
                    background: rgb(21, 21, 65);


                    .apropos-moov{
                        background: #fff;
                        height: 100%;
                        width: 7px;
                        animation: barAnim 10s infinite;

                        @keyframes barAnim {
                            0%{
                                transform: translateX(0px);
                            }
                            50%{
                                transform: translateX(142px);
                            }
                            100%{
                                transform: translateX(0px);
                            }
                        }
                    }
                }
            }
            .apropos-desc{
                color: grey;
                line-height: 1.9;
                text-align: justify;
                font-weight: 300;
                @include tablette{
                    width: 95%;
                }
            }

            .apropos-rows{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;

                .apropos-row{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    .apropos-icon{
                        color: rgb(69, 69, 226);
                    }

                    .apropos-h4{
                        font-size: 1rem;
                    }
                }
            }
        }
        .apropos-right{
            flex: 1;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            @include tablette{
                width: 100%;
            }
            .apropos-img{
                width: 100%;
                height: 250px;
                object-fit: cover;
                @include tablette{
                    width: 95%;
                    height: 100%;
                }
            }
        }
    }
}
