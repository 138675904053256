@import '../../style.scss';
.services{
    width: 100%;
    padding: 50px 0;
    .services-wrapper{
        max-width: 1025px;
        margin: 0 auto;
        @include laptop{
            width: 900px;
        }
        @include tablette{
            width: 850px;
        }
        @include mobile{
            width: 400px;
        }
        @include mobileXR{
            width: 370px;
            position: relative;
            overflow: hidden;
        }
        @include mobile12pro{
            width: 350px;
        }
        @include mobileSE{
            width: 345px;
        }
        .client-cont-title{
            display: flex;
            flex-direction: column;
            gap: 10px;
            text-align: center;
            .client-title{
                font-size: 1.2rem;
                color: rgb(21, 21, 65);
            }
            .client-sous-title{
                width: 60%;
                margin: 0 auto;
                font-size: 2rem;
                font-family: 'Anton', sans-serif;
                color: rgb(21, 21, 65);
                @include mobile{
                    width: 100%;
                 }
            }
            .apropos-barre{
                display: flex;
                align-items: center;
                margin: 0 auto;
                height: 5px;
                width: 15%;
                background: rgb(21, 21, 65);
                overflow: hidden;
                .apropos-moov{
                    background: #fff;
                    height: 100%;
                    width: 7px;
                    animation: barAnim 10s infinite;

                    @keyframes barAnim {
                        0%{
                            transform: translateX(0px);
                        }
                        50%{
                            transform: translateX(142px);
                        }
                        100%{
                            transform: translateX(0px);
                        }
                    }
                }
            }
        }
        .services-container{
            width: 100%;
            margin-top: 100px;
            .services-rows{
                display: grid;
                grid-template-columns: repeat(3,1fr);
                gap:20px;
                @include mobile{
                    display: flex;
                    flex-direction: column;
                }
                .services-row{
                    width: 100%;
                    margin-top: 20px;
                    box-shadow: 0px 4px 14px hsl(355deg 25% 15% / 10%);
                    border-radius: 10px;
                    height: 500px;
                    &:hover{
                        border-bottom: 1px solid rgb(21, 21, 65);
                    }
                    .services-image{
                        width: 100%;
                        .service-img{
                            width: 100%;
                            height: 200px;
                            object-fit: cover;
                        }
                    }
                    .service-bottom{
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        .service-rows-title{
                            padding: 10px;

                            .service-h2{
                                text-align: center;
                                color : rgb(21, 21, 65);
                                font-size: 1.5rem;
                                font-family: 'Anton', sans-serif;
                                font-weight: 300;
                            }
                        }
                        .service-desc{
                            color: gray;
                            padding: 20px;
                            text-align: justify;
                            font-weight: 300;
                            font-size: .9rem;
                            line-height: 1.6;
                            display: -webkit-box;
                            -webkit-line-clamp: 5;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                        .service-btn{
                            margin: 20px;
                            background: rgb(21, 21, 65);
                            padding: 8px 15px;
                            color: #fff;
                            border-radius: 5px;
                            font-size: .9rem;
                            font-weight: 300;
                            width: max-content;
                        }
                    }
                    
                }
            }
        }      
    }
}
