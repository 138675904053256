@import '../../style.scss';
.footer{
    width: 100%;
    padding: 20px 0;
    background: #12141c;
    .footer-container{
        max-width: 1025px;
        margin: 0 auto;
        @include laptop{
            width: 900px;
        }
        @include mobileXR{
            width: 360px;
            position: relative;
            overflow: hidden;
        }
        @include mobile12pro{
            width: 350px;
        }
        @include mobileSE{
            width: 340px;
        }
        .experience-container{
            width: 100%;
            display: flex;
            justify-content: center;
            .footer-rows{
                display: flex;
                gap: 20px;
                padding: 50px 0;
                @include mobile{
                    display: grid;
                    grid-template-columns: repeat(2,1fr);
                }
                .footer-left{
                    flex: 2;
                    display: flex;
                    flex-direction: column;
                    .footer-logo{

                        .footer-img{
                            height: 60px;
                            width: 60px;
                            object-fit: cover;
                            border-radius: 50%;
                            margin-bottom: 20px;
                            @include mobileXR{
                                height: 50px;
                                width: 50px;
                            }
                        }
                    }
                    .footer-desc{
                        font-size: .9rem;
                        color: grey;
                        padding-bottom: 20px;
                        line-height: 1.5;
                        font-weight: 300;
                        @include mobileXR{
                            font-size: .8rem;
                        }
                    }
                }
                .footer-center1{
                    flex: 1;
                    .footer-h2{
                        position: relative;
                        color: #747070;
                        font-family: 'Anton', sans-serif;
                        letter-spacing: 1.8;
                        font-size: 1.2rem;
                        margin-bottom: 20px;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        @include mobileXR{
                            font-size: 1rem;
                        }
                        &::after{
                            content: '';
                            width: 45px;
                            height: 2px;
                            background: #747070;
                            animation: anibar 5s infinite;
                            position: relative;
                            overflow: hidden;
                        }
                    }
                    .footer-ul{
                        display: flex;
                        flex-direction: column;
                        gap: 6px;
                        @include mobileXR{
                            gap: 5px;
                        }
                        li{
                            list-style: none;
                            .footerLi{
                                color: gray;
                                font-weight: 300;
                                font-size: .9rem;
                                @include mobileXR{
                                    font-size: .8rem;
                                }
                            }
                        }
                    }
                }
                .footer-center2{
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    .footer-h2{
                        position: relative;
                        color: #747070;
                        font-family: 'Anton', sans-serif;
                        letter-spacing: 1.8;
                        font-size: 1.2rem;
                        margin-bottom: 20px;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        @include mobileXR{
                            font-size: 1rem;
                        }
                        &::after{
                            content: '';
                            width: 45px;
                            height: 2px;
                            background: #747070;
                            animation: anibar 5s infinite;
                            position: relative;
                            overflow: hidden;
                        }
                    }
                    .footer-icons{
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        .icons-footer{
                            font-size: 2rem;
                            color: #747070;
                            @include mobileXR{
                                font-size: 1.5rem;
                            }
                        }
                        .contact-right-row{

                            .footer-desc{
                                font-size: .9rem;
                                color: grey;
                                padding-bottom: 20px;
                                font-weight: 300;
                                @include mobileXR{
                                    font-size: .8rem;
                                }
                            }
                        }
                    }

                }
                .footer-right{
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    .footer-h2{
                        position: relative;
                        color: #747070;
                        font-family: 'Anton', sans-serif;
                        letter-spacing: 1.8;
                        font-size: 1.2rem;
                        margin-bottom: 20px;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        @include mobileXR{
                            font-size: 1rem;
                        }
                        &::after{
                            content: '';
                            width: 45px;
                            height: 2px;
                            background: #747070;
                            animation: anibar 5s infinite;
                            position: relative;
                            overflow: hidden;
                        }
                    }
                    .footer-input{
                        border: none;
                        width: 100%;
                        padding: 8px;
                        border: 1px solid gray;
                        background: #f1f1f1;
                        outline: none;
                        border-radius: 5px;
                        margin-bottom: 20px;
                        @include mobileXR{
                            width: 90%;
                            padding: 5px;
                        }
                    }
                    .footer-sociaux{
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        @include mobileXR{
                            gap:8px
                        }
                        .footer-social{
                            position: relative;
                            height: 40px;
                            width: 40px;
                            border-radius: 50%;
                            background: rgba(192, 186, 186, 0.671);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            @include mobileXR{
                                width: 25px;
                                height: 25px;
                            }
                            cursor: pointer;
                            &:hover{
                                transform: scale(1.1);
                            }
                            .icon-footer{
                                font-size: 1.5rem;
                                color: #12141c;
                                @include mobileXR{
                                    font-size: 1rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}