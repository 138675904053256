@import '../../style.scss';

.topbar{
    display: grid;
    align-items: center;
    width: 100%;
    height: 30px;
    background: rgb(21, 21, 65);
    @include laptop{
        display: none;
    }

    .topbar-wrapper{
        width: 1025px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;

        .topbar-left{
            display: flex;
            align-items: center;
            gap: 20px;

            .topbar-left-icons{
                display: flex;
                align-items: center;

                .topbar-icons{
                    color: white;
                    font-size: 1.2rem;
                    padding-right: 5px;
                }
                .topbar-adresse{
                    font-size: .8rem;
                
                    @include mobile{
                        font-size: .8rem;

                    }
                    @include mobileSE{
                        font-size: .7rem;

                    }

                }
            }
            @include mobile{
                .topNum{
                    display: none;
                }
            }
        }
        .topbar-right{
            display: flex;
            gap: 10px;

            .topbar-rsx{
                font-size: 1.5rem;
                padding: 4px;
                border-radius: 50%;
                border: 1px solid #fff;
                &:hover{
                    cursor: pointer;
                    transform: scale(1.1);
                }
            }
        }
    }
}