@import '../../style.scss';
.specialite{
    width: 100%;
    padding: 50px 0;
    height: 100%;
    background: linear-gradient(rgba(104, 104, 158, 0.055),rgba(81, 81, 180, 0.021));
    .specialite-wrapper{
        max-width: 1025px;
        margin: 0 auto;
        @include laptop{
            width: 900px;
        }
        @include tablette{
            width: 850px;
        }
        @include mobileXR{
            width: 370px;
            position: relative;
            overflow: hidden;
        }
        @include mobileSE{
            width: 340px;
        }
        .client-cont-title{
            display: flex;
            flex-direction: column;
            gap: 10px;
            text-align: center;
            .client-sous-title{
                width: 60%;
                margin: 0 auto;
                font-size: 2rem;
                font-family: 'Anton', sans-serif;
                color: rgb(21, 21, 65);
                @include mobile{
                    width: 100%;
                 }
            }
            .apropos-barre{
                display: flex;
                align-items: center;
                margin: 0 auto;
                height: 5px;
                width: 15%;
                background: rgb(21, 21, 65);
                overflow: hidden;
                .apropos-moov{
                    background: #fff;
                    height: 100%;
                    width: 7px;
                    animation: barAnim 10s infinite;

                    @keyframes barAnim {
                        0%{
                            transform: translateX(0px);
                        }
                        50%{
                            transform: translateX(142px);
                        }
                        100%{
                            transform: translateX(0px);
                        }
                    }
                }
            }
        }
        .specialite-container{
            margin-top: 100px;
            display: grid;
            grid-template-columns: repeat(4,1fr);
            gap: 20px;
            @include mobile{
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .specialite-row{
                width: 200px;
                text-align: center;
                position: relative;
                box-shadow: 0px 0px 5px rgba(0,0,0,0.100);
                margin: 0;
                background: rgb(21, 21, 65);
                border-radius: 5px 5px 0 0;
                overflow: hidden;
                cursor: pointer;
                &:hover{
                    transform: scale(1.1);
                    border: 1px solid rgb(21, 21, 65);
                }
                @include mobile{
                    width: 300px;
                }
                .specialite-img{
                    width: 100%;
                    height: 140px;
                    object-fit: cover;
                }
                .specialite-desc{   
                    width: 100%;
                    color: #fff;
                    padding: 5px 0 10px 0;
                    font-family: 'Anton', sans-serif;
                    font-weight: 300;
                    font-size: .9rem;
                    letter-spacing: 1.5px;
                }
            }
        }
    }
}